import { useQuery } from "@tanstack/react-query";
import { useSearchParamState } from "./useSearchParams";
import { orderService } from "../service/orderServices";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import { useSelector } from "react-redux";

type Options = {
  showActiveDefault?: boolean;
};
export function useOrdersData(options?: Options) {
  const activeOrg = useSelector(getActiveOrganization);
  const [plant, setPlant] = useSearchParamState(`plant`, null);
  const [sort, setSort] = useSearchParamState(`sort`, "orderDate");
  const [filterType, setFilterType] = useSearchParamState(
    `filter-type`,
    "everything",
  );
  const [filterValue, setFilterValue] = useSearchParamState(`filter-value`, "");
  const [activeChecked, setActiveChecked] = useSearchParamState(
    `showActive`,
    options?.showActiveDefault ?? false,
  );
  const [archivedChecked, setArchivedChecked] = useSearchParamState(
    `showArchived`,
    false,
  );
  const [page] = useSearchParamState(`page`, 0);
  const [limit] = useSearchParamState(`limit`, 100);

  const { data } = useQuery({
    throwOnError: true,
    queryKey: [
      `orders`,
      activeOrg.organization_id,
      page,
      limit,
      plant,
      sort,
      filterType,
      filterValue,
      activeChecked,
      archivedChecked,
    ],
    queryFn: () =>
      orderService.getOrders({
        orgId: activeOrg.organization_id,
        sort,
        page,
        limit,
        filters: {
          filterType,
          filterValue,
          activeChecked,
          archivedChecked,
          plant,
        },
      }),
  });

  const orders = data?.data || [];

  return {
    plant,
    setPlant,
    sort,
    setSort,
    filterType,
    setFilterType,
    filterValue,
    setFilterValue,
    activeChecked,
    setActiveChecked,
    archivedChecked,
    setArchivedChecked,
    page,
    limit,
    orders,
  };
}
