/** @jsxImportSource @emotion/react */
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import { mockPlants } from "pages/rtscao/mockData";
import { PartData } from "pages/rtscao/mockData";
import { Modal } from "components/molecules/Modal.molecule";
//@ts-ignore this is a temporary file anyway with hardcoded part data, eventually partData will be replaced with a DB/API call
import { partData } from "pages/rtscao/service/partData";
import { useR2SContext } from "pages/rtscao/service/R2SContext";
import { AsyncPaginate, AsyncPaginateProps } from "react-select-async-paginate";
import Colors from "styles/colors";
import { useState } from "react";
import { Button, GreenButton } from "../Shared";
import { Form } from "pages/rtscao/formLibrary/useForm";
import { components } from "react-select";

interface LocationValue {
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  oem: boolean;
  supplier: boolean;
}

export interface Location {
  value: LocationValue;
  label: string;
}

const suppliers = new Map<string, PartData>();
partData.forEach((part: PartData) => {
  suppliers.set(part.supplierName, part);
});

export const locations: Location[] = [];

mockPlants.forEach((plant) => {
  locations.push({
    value: {
      name: plant?.value?.plantName,
      street: plant?.value?.street,
      city: plant?.value?.city,
      state: plant?.value?.state,
      zip: plant?.value?.zip,
      oem: true,
      supplier: false,
    },
    label: plant?.value?.plantName,
  });
});

// add suppliers to locations
suppliers.forEach((part, supplierName) => {
  locations.push({
    value: {
      name: part.supplierName,
      street: part.supplierStreet,
      city: part.supplierCity,
      state: part.supplierState,
      zip: part.supplierZip,
      oem: false,
      supplier: true,
    },
    label: supplierName,
  });
});

const filterLocations = async (
  inputStr: string,
): Promise<{ options: Location[] }> => {
  let results: Location[] = [];
  try {
    results = locations
      .filter(
        ({ value }) =>
          value?.name?.toLowerCase().includes(inputStr?.toLowerCase()) ||
          value?.city?.toLowerCase().includes(inputStr?.toLowerCase()) ||
          value?.state?.toLowerCase().includes(inputStr?.toLowerCase()) ||
          value?.zip?.toLowerCase().includes(inputStr?.toLowerCase()) ||
          value?.street?.toLowerCase().includes(inputStr?.toLowerCase()),
      )
      .slice(0, 100);
  } catch (e) {
    return { options: [] };
  }

  return { options: results };
};

interface OptProps {
  label: string;
  innerProps: any;
  data: Location;
  selectProps: any;
  isDisabled: boolean;
  isSelected: boolean;
  isFocused: boolean;
}

const Opt: React.FC<OptProps> = ({
  label,
  innerProps,
  data,
  selectProps,
  isDisabled,
  isSelected,
  isFocused,
}) => {
  const street = data?.value?.street;
  const city = data?.value?.city;
  const state = data?.value?.state;
  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: "12px",
        paddingLeft: "10px",
        paddingBottom: "10px",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        color: isDisabled
          ? "#aaa"
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
      }}
    >
      <div>
        <strong>{label}</strong> - {street} {city} {state}
      </div>
    </div>
  );
};

const formatOptionLabel = ({ value, label }: Location): JSX.Element => {
  return (
    <div>
      <strong>{label}</strong> - {value?.street} {value?.city} {value?.state}
    </div>
  );
};

interface SelectLocationProps
  extends Partial<AsyncPaginateProps<Location, any, any, any>> {
  setSelected: (selected: Location | null) => void;
  selected: Location | null;
  name?: string;
  namePrefix?: string;
  required?: boolean;
}

export function SelectLocation(props: SelectLocationProps): JSX.Element {
  const { editMode } = useR2SContext();
  const [newLocationModal, setNewLocationModal] = useState(false);

  if (editMode === false) {
    return (
      <div>
        <strong>{props.selected?.label}</strong> -{" "}
        {props.selected?.value?.street} {props.selected?.value?.city}{" "}
        {props.selected?.value?.state}
      </div>
    );
  }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span
          onClick={() => setNewLocationModal(true)}
          css={{
            fontSize: 16,
            color: "black",
            fontWeight: 900,
            cursor: "pointer",
          }}
        >
          Add New Location
        </span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <>
      <Modal
        centered
        show={newLocationModal}
        onHide={() => {}}
        css={{
          "--bs-modal-width": "400px",
          "&>div>div": { width: "400px" },
        }}
      >
        <Modal.Header
          title="Create Order"
          customCss={{
            backgroundColor: "#282828",
            color: "white",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
          }}
          closeButton={false}
        />
        <Form onSubmit={() => {}}>
          <Modal.Body>
            <div>Enter New Location</div>
          </Modal.Body>
          <Modal.Footer
            style={{
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          >
            <Button type="button" onClick={() => setNewLocationModal(false)}>
              Cancel
            </Button>
            <GreenButton type="submit">Create</GreenButton>
          </Modal.Footer>
        </Form>
      </Modal>

      <AsyncPaginate<Location, any, any>
        defaultOptions
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: props.isDisabled ? "#ffffff00" : "white",
            borderRadius: 4,
            border: "2px solid #dedede",
            flex: 1,
            minHeight: 0,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0px 4px",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: "0px 8px",
          }),
        }}
        placeholder="Select Location"
        loadOptions={(inp: string) => filterLocations(inp)}
        onChange={props.setSelected}
        value={props.selected}
        components={{
          Option: Opt,
          NoOptionsMessage,
        }}
        {...props}
      />

      <ValidatorInput
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.label`
            : "location"
        }
        required={props.required}
        css={{ display: "none" }}
        value={props.selected?.label ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            props.setSelected(null);
          }
        }}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.name` : "name"
        }
        value={props.selected?.value?.name ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.address`
            : "street"
        }
        value={props.selected?.value?.street ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.city` : "city"
        }
        value={props.selected?.value?.city ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.state` : "state"
        }
        value={props.selected?.value?.state ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.zip` : "zip"
        }
        value={props.selected?.value?.zip ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.oem` : "oem"
        }
        value={props.selected?.value?.oem?.toString() ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.supplier`
            : "supplier"
        }
        value={props.selected?.value?.supplier?.toString() ?? ""}
      />
    </>
  );
}
