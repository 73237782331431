/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useSetTitle } from "components/hooks/useSetTitle";
import { t } from "i18next";
import { OrderCard } from "./components/OrderCard/OrderCard";
import { PageHeader } from "./components/PageHeader/PageHeader";
import { useOrdersData } from "./hooks/useOrdersData";
import { R2SProvider } from "./service/R2SContext";

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export function RtsCaoOemView() {
  useSetTitle(t("rtscao:Ready to Ship Confirmation and Opportunities"));
  const {
    plant,
    setPlant,
    sort,
    setSort,
    filterType,
    setFilterType,
    filterValue,
    setFilterValue,
    activeChecked,
    setActiveChecked,
    archivedChecked,
    setArchivedChecked,
    orders: _orders,
  } = useOrdersData();

  const opportunities = _orders.filter(
    (order) =>
      order.opportunities &&
      order.statusItems[0].status === "active" &&
      order.statusItems[2].status !== "active",
  );
  const orders = _orders.filter(
    (order) => !order.opportunities || order.statusItems[2].status === "active",
  );

  return (
    <div css={{ padding: 20 }}>
      <PageHeader
        variant="oem"
        plant={plant}
        setPlant={setPlant}
        sort={sort}
        setSort={setSort}
        filterType={filterType}
        setFilterType={setFilterType}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        activeChecked={activeChecked}
        setActiveChecked={setActiveChecked}
        archivedChecked={archivedChecked}
        setArchivedChecked={setArchivedChecked}
        orders={orders}
        opportunities={opportunities}
      ></PageHeader>
      <CardList>
        {orders.map((order) => (
          <R2SProvider value={{ editMode: false }} key={order.id}>
            <OrderCard key={order.id} order={order} variant="oem" />
          </R2SProvider>
        ))}
      </CardList>
    </div>
  );
}
