/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { SelectWithLabel } from "./SelectWithLabel";
import { Checkbox } from "./Checkbox";
import { FontSize, Icon, IconType } from "components/atoms/Icon.atom";
import { faLocationDot, faBan } from "@fortawesome/pro-solid-svg-icons";
import {
  mockPlantHashMap,
  mockPlants,
  sortKeyHashMap,
  sortKeys,
} from "../mockData";
import { ValidatorInput } from "../formLibrary/Input";
import { SVGAttributes, useEffect, useRef, useState } from "react";
import { SelectAsync } from "components/atoms/SelectAsync.atom";
import { useR2SContext } from "../service/R2SContext";

export const Card = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  display: flex;
`;

export const InfoText = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
`;

export const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
`;

interface PlantSelectProps {
  plant: string | null;
  setPlant:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<null>>;
}

export function PlantSelect({ setPlant, plant }: PlantSelectProps) {
  return (
    <SelectWithLabel
      isClearable={true}
      isSearchable={true}
      label="Plant"
      loadOptions={async (inputStr: string) => ({
        options: mockPlants.filter((plant) =>
          plant.label.toLowerCase().includes(inputStr.toLowerCase()),
        ),
        hasMore: false,
      })}
      onChange={(selected: any) => setPlant(selected?.value.id)}
      value={mockPlantHashMap[plant ?? ""]}
    />
  );
}

interface SortSelectProps {
  setSort: (value: string) => void;
  sort: string;
}

export function SortSelect({ setSort, sort }: SortSelectProps) {
  return (
    <SelectWithLabel
      isSearchable={true}
      label="Sort By"
      loadOptions={async (inputStr: string) => ({
        options: sortKeys.filter((key) =>
          key.label.toLowerCase().includes(inputStr.toLowerCase()),
        ),
        hasMore: false,
      })}
      onChange={(selected: any) => setSort(selected?.value)}
      value={sortKeyHashMap[sort]}
    />
  );
}

export function ReasonCodeSelect({ setSort, sort }: SortSelectProps) {
  return (
    <SelectWithLabel
      isSearchable={true}
      label="Sort By"
      loadOptions={async (inputStr: string) => ({
        options: sortKeys.filter((key) =>
          key.label.toLowerCase().includes(inputStr.toLowerCase()),
        ),
        hasMore: false,
      })}
      onChange={(selected: any) => setSort(selected?.value)}
      value={sortKeyHashMap[sort]}
    />
  );
}

interface CheckboxesProps {
  activeChecked: boolean;
  setActiveChecked: (checked: boolean) => void;
  archivedChecked: boolean;
  setArchivedChecked: (checked: boolean) => void;
}

export const Checkboxes: React.FC<CheckboxesProps> = ({
  activeChecked,
  setActiveChecked,
  archivedChecked,
  setArchivedChecked,
}) => {
  return (
    <>
      <span css={{ marginRight: 20 }}>Show</span>
      <Checkbox
        checked={activeChecked}
        onChange={() => setActiveChecked(!activeChecked)}
        label="Active"
      />
      <Checkbox
        checked={archivedChecked}
        onChange={() => setArchivedChecked(!archivedChecked)}
        label="Archived"
      />
    </>
  );
};

export const AddressBlock = styled.div`
  flex: 1;
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.h2`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

export const AddressTitle = styled.h4`
  margin: 0 0 5px 0;
  font-size: 14px;
  font-weight: bold;
`;

export const AddressTitle2 = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;

export const Address = styled.p`
  margin: 0;
  min-height: 21px;
  font-size: 14px;
`;

export const AddressSection = styled(Card)`
  box-shadow: none;
  flex-direction: column;
  padding: 20px;
  flex: 1;
`;

export const DockInfo = styled.div`
  display: flex;
  color: #282828;
`;

export const ThreeWordsPrefixStyled = styled.span`
  color: #cf3e37;
`;

interface ThreeWordsProps {
  children?: React.ReactNode;
}

export const ThreeWords: React.FC<ThreeWordsProps> = ({ children }) => {
  if (!children) {
    return null;
  }
  return (
    <>
      <ThreeWordsPrefixStyled>{`///`}</ThreeWordsPrefixStyled>
      {children}
    </>
  );
};

export const DockTitle = styled.p`
  font-size: 14px;
  opacity: 40%;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const DockTimeStyled = styled.div`
  flex: 2;
`;

export const DockLocationStyled = styled.div`
  flex: 2;
`;

export const AtDockStyled = styled.div`
  flex: 1;
`;

interface DockTimeProps {
  children: React.ReactNode;
}

export const DockPickupTime: React.FC<DockTimeProps> = ({ children }) => {
  return (
    <DockTimeStyled>
      <DockTitle>Pickup By</DockTitle>
      <InfoText>{children}</InfoText>
    </DockTimeStyled>
  );
};

export const DockDeliverTime: React.FC<DockTimeProps> = ({ children }) => {
  return (
    <DockTimeStyled>
      <DockTitle>Deliver By</DockTitle>
      <InfoText>{children}</InfoText>
    </DockTimeStyled>
  );
};

interface DockLocationProps {
  children: React.ReactNode;
}

export const DockPickupLocation: React.FC<DockLocationProps> = ({
  children,
}) => {
  return (
    <DockLocationStyled>
      <DockTitle>Pick Up Location</DockTitle>
      <InfoText>{children}</InfoText>
    </DockLocationStyled>
  );
};

export const DockDeliverLocation: React.FC<DockLocationProps> = ({
  children,
}) => {
  return (
    <DockLocationStyled>
      <DockTitle>Delivery Location</DockTitle>
      <InfoText>{children}</InfoText>
    </DockLocationStyled>
  );
};

export const DockNotes: React.FC<DockLocationProps> = ({ children }) => {
  return (
    <DockLocationStyled>
      <DockTitle>Notes</DockTitle>
      <InfoText>{children}</InfoText>
    </DockLocationStyled>
  );
};

interface AtDockProps {
  atDock: string;
}

export const AtDock: React.FC<AtDockProps> = ({ atDock }) => {
  return (
    <AtDockStyled>
      <DockTitle>At Dock</DockTitle>
      <InfoText>{atDock}</InfoText>
    </AtDockStyled>
  );
};

export const MapDot = (
  <Icon
    style={{ color: "#666" }}
    type={IconType.FontAwesome}
    src={faLocationDot}
    size={FontSize.size16}
  />
);

export const IconBan = (
  <Icon
    style={{ color: "#CF3E37", position: "relative", top: 2 }}
    type={IconType.FontAwesome}
    src={faBan}
    size={FontSize.size16}
  />
);

export const Title = styled.h1`
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
  margin-top: 10px;
`;

export const Container = styled(Card)`
  box-shadow: none;
  background-color: #f0f0f0bf;
  padding: 10px;
  margin: 0px 10px 10px;
`;

export function formatISOToCustom(isoString: string): string {
  if (!isoString) {
    return "";
  }
  const date = new Date(isoString);

  if (date.toString() === "Invalid Date") {
    return "";
  }

  // Format date
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  // Format time
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Get local timezone abbreviation
  const timeZone = date
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  return `${month}/${day}/${year} ${hours}:${minutes} ${timeZone}`;
}

export const CancelButton = styled(Button)`
  background-color: #cf3e37;
  color: white;
`;

export const GreenButton = styled(Button)`
  background-color: #007c4d;
  color: white;
`;

interface DateTimeInputProps {
  defaultValue?: string;
  name?: string;
  required?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
  route?: any;
  fRef?: any;
  pairRef?: any;
}

export const DateTimeInput: React.FC<DateTimeInputProps> = (props) => {
  const { editMode } = useR2SContext();
  const ref = useRef<HTMLInputElement>(null);
  if (props.fRef && ref.current) {
    props.fRef.current = ref.current;
  }
  const [val, setVal] = useState<Date | undefined>(
    props.defaultValue ? new Date(props.defaultValue) : undefined,
  );

  useEffect(() => {
    setVal(props.defaultValue ? new Date(props.defaultValue) : undefined);
  }, [props.defaultValue]);

  if (editMode === false) {
    return <div>{formatISOToCustom(val?.toISOString() ?? "")}</div>;
  }

  if (ref.current) {
    setTimeout(() => {
      if (ref.current) {
        const evtInput = new Event("blur", { bubbles: true });
        ref.current.dispatchEvent(evtInput);
      }
    }, 100);
  }

  return (
    <>
      <DateTimePicker
        //@ts-ignore css prop not existing on the DateTimePicker component (emotion converts this to className under the hood)
        css={{
          border: "2px solid #dedede",
          borderRadius: 4,
          height: "33px",
          "&>div": { border: "none", height: "29px" },
          "& button.rw-btn.rw-btn-select": { height: "25px" },
        }}
        value={val ?? undefined}
        onChange={setVal}
        format="l HH:mm"
        disabled={props.disabled}
      />
      <ValidatorInput
        fRef={ref}
        name={props.name ?? "datetime"}
        required={props.required}
        css={{ display: "none" }}
        value={val ? val.toISOString() : ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            setVal(undefined);
          }
          props.onChange && props.onChange(ev.target.value);
        }}
        customValidator={(inputVal: string) => {
          if (inputVal && new Date(inputVal) < new Date()) {
            return "Date must be in the future";
          }
          const twoHoursFromNow = new Date();
          twoHoursFromNow.setHours(twoHoursFromNow.getHours() + 4);
          if (inputVal && new Date(inputVal) < twoHoursFromNow) {
            return "Date must be at least 4 hours from now";
          }

          if (props.route && props.name?.includes("destination")) {
            if (props.route.summary.duration === null) {
              return true;
            }

            if (props.route.summary.conflict) {
              const minArrivalDate = new Date(
                props.route.summary.minArrivalTime,
              );
              const minArrivalFormatted = minArrivalDate.toLocaleTimeString(
                [],
                {
                  day: "2-digit",
                  month: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                },
              );
              return `Practical delivery for this route is after ${minArrivalFormatted}`;
            }
          }

          if (props.route && props.name?.includes("origin")) {
            if (props.route.summary.duration === null) {
              return true;
            }

            setTimeout(() => {
              if (props.pairRef && props.pairRef.current) {
                props.pairRef.current.dispatchEvent(
                  new Event("blur", { bubbles: true }),
                );
              }
            }, 100);
          }

          return true;
        }}
      />
    </>
  );
};

interface SelectProps {
  name: string;
  defaultValue?: any;
  required?: boolean;
  [key: string]: any;
}

export const Select: React.FC<SelectProps> = (props) => {
  const { editMode } = useR2SContext();
  if (!props.name) {
    throw new Error("Select component must have a name prop");
  }

  const [val, setVal] = useState(props.defaultValue);

  if (editMode === false) {
    return <div>{val?.label}</div>;
  }

  return (
    <>
      <SelectAsync
        styles={{
          control: (styles: any) => ({
            ...styles,
            backgroundColor: "white",
            borderRadius: 4,
            border: "2px solid #dedede",
            flex: 1,
            minHeight: 0,
          }),
          dropdownIndicator: (styles: any) => ({
            ...styles,
            padding: "0px 4px",
          }),
          valueContainer: (styles: any) => ({
            ...styles,
            padding: "0px 8px",
          }),
          menuPortal: (styles: any) => ({
            ...styles,
            zIndex: 99999999,
            position: "absolute",
          }),
        }}
        {...props}
        onChange={setVal}
        value={val}
      />
      <ValidatorInput
        name={props.name}
        required={props.required}
        css={{ display: "none" }}
        value={val?.value ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            setVal(null);
          }
        }}
      />
    </>
  );
};

export const SubTitle = styled.p`
  font-size: 14px;
  margin: 0;
  color: #666;
`;

export function getDurationBetweenDates(
  date1: Date | string,
  date2: Date | string,
): string {
  const timeBetween = new Date(date1).getTime() - new Date(date2).getTime();
  const minutes = Math.floor(timeBetween / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${minutes}m`;
}

export function getTimeSince(date: Date | string): string {
  return getDurationBetweenDates(new Date(), date);
}

export function getDaysAndHoursBetweenDates(
  startDate: Date | string,
  endDate: Date | string,
): string {
  // Calculate the difference in milliseconds
  const diffMs = new Date(endDate).getTime() - new Date(startDate).getTime();

  // Convert to days and hours
  const days = Math.floor(diffMs / (24 * 60 * 60 * 1000));
  const hours = Math.floor((diffMs % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

  // Create the formatted string
  let result = "";
  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0 || (days === 0 && hours === 0)) {
    result += `${hours}h`;
  }

  return result.trim();
}

export function formatSecondsIntoDaysAndHours(seconds: number): string {
  // Calculate days and hours
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / 3600);

  // Create the formatted string
  let result = "";
  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0 || (days === 0 && hours === 0)) {
    result += `${hours}h`;
  }

  return result.trim();
}

export function formatUSD(amount: number | string): string {
  if (typeof amount === "string") {
    amount = parseFloat(amount);
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
}

export const truckColors = {
  onShipment: "#0D6EFD",
  premium: "#3DAD2A",
  myCurrentCarrier: "#0D6EFD",
  freeAsset: "#CF3E37",
  openNetwork: "#FF9334",
} as const;

export type TruckColor = keyof typeof truckColors;
type TruckSvgProps = SVGAttributes<SVGSVGElement> & {
  color: TruckColor;
};

export function TruckSvg(props: TruckSvgProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={truckColors[props.color]} />
      <rect
        x="2.20679"
        y="7.72418"
        width="11.0345"
        height="3.31035"
        rx="1.65517"
        fill="#358ACE"
      />
      <rect
        x="2.20679"
        y="7.72418"
        width="11.0345"
        height="3.31035"
        rx="1.65517"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M7.72403 5.81436L7.43975 5.51727H4.28185C3.36831 5.51727 3.07793 6.11144 3.07793 6.11144L2.20679 7.72417V9.37934H7.72403V5.81436Z"
        fill="#358ACE"
      />
      <path
        d="M4.96544 7.72414H2.75854L3.38909 6.62069C3.38909 6.62069 3.70436 6.06897 4.3349 6.06897H4.96544V7.72414Z"
        fill="white"
      />
      <path
        d="M4.96551 12.1379C5.57493 12.1379 6.06896 11.6439 6.06896 11.0345C6.06896 10.4251 5.57493 9.93103 4.96551 9.93103C4.35609 9.93103 3.86206 10.4251 3.86206 11.0345C3.86206 11.6439 4.35609 12.1379 4.96551 12.1379Z"
        fill="#282828"
      />
      <path
        d="M4.96009 11.5619C5.26479 11.5649 5.51421 11.3203 5.51719 11.0156C5.52017 10.7109 5.27558 10.4615 4.97089 10.4585C4.66619 10.4555 4.41677 10.7001 4.41379 11.0048C4.41081 11.3095 4.6554 11.5589 4.96009 11.5619Z"
        fill="white"
      />
      <path
        d="M10.4826 12.1379C11.092 12.1379 11.586 11.6439 11.586 11.0345C11.586 10.4251 11.092 9.93103 10.4826 9.93103C9.87318 9.93103 9.37915 10.4251 9.37915 11.0345C9.37915 11.6439 9.87318 12.1379 10.4826 12.1379Z"
        fill="#282828"
      />
      <path
        d="M10.4772 11.6104C10.7819 11.6134 11.0313 11.3688 11.0343 11.0641C11.0373 10.7594 10.7927 10.51 10.488 10.507C10.1833 10.5041 9.93386 10.7487 9.93088 11.0533C9.9279 11.358 10.1725 11.6075 10.4772 11.6104Z"
        fill="white"
      />
      <path
        d="M11.5381 4.41382H7.22027C6.9149 4.41382 6.62203 4.53691 6.40609 4.75602C6.19016 4.97513 6.06885 5.27231 6.06885 5.58218V9.37934H12.6895V5.58218C12.6895 5.27231 12.5682 4.97513 12.3523 4.75602C12.1364 4.53691 11.8435 4.41382 11.5381 4.41382Z"
        fill="#E7E7E7"
      />
      <rect
        x="6.06885"
        y="4.41382"
        width="7.17241"
        height="4.96552"
        rx="1"
        fill="#E7E7E7"
      />
    </svg>
  );
}
