/* eslint-disable curly */
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import {
  calculateArrivalTime,
  getDrivingDistance,
  getRoute,
  updateDestDate,
  updateOriginDate,
} from "pages/rtscao/service/heremapService";
import React, { useEffect, useState } from "react";
import { SelectDock } from "../CreateOrder/SelectDock";
import {
  Location,
  locations,
  SelectLocation,
} from "../CreateOrder/SelectLocation";
import {
  AddressSection,
  AddressTitle,
  DateTimeInput,
  DockInfo,
  DockTimeStyled,
  DockTitle,
  SectionTitle,
  formatSecondsIntoDaysAndHours,
  getDaysAndHoursBetweenDates,
  MapDot,
} from "../Shared";
import { SelectLoadType } from "../CreateOrder/SelectLoadType";

interface Route {
  error?: any;
  departure?: {
    newTime: string;
  };
  arrival?: {
    newTime: string;
  };
  hmOrigin?: {
    position: {
      lat: number;
      lng: number;
    };
  };
  hmDest?: {
    position: {
      lat: number;
      lng: number;
    };
  };
  summary?: {
    duration: number;
    driveTime: string;
    miles: number;
    practicalDeliveryTime: string;
  };
}

interface Order {
  route?: {
    origin?: {
      lat: number;
      long: number;
    };
    destination?: {
      lat: number;
      long: number;
    };
    summary?: {
      duration: number;
      driveTime: string;
      miles: number;
      practicalDeliveryTime: string;
    };
  };
}

async function getAndSetRoute(
  origin: Location | null,
  dest: Location | null,
  setRoute: React.Dispatch<React.SetStateAction<Route | null>>,
  order: Order | null,
) {
  if (!origin || !dest) {
    return;
  }

  if (order) {
    setRoute({
      departure: {
        newTime: (origin as any).lateDate,
      },
      arrival: {
        newTime: (dest as any).lateDate,
      },
      hmOrigin: {
        position: {
          lat: order?.route?.origin?.lat ?? 0,
          lng: order?.route?.origin?.long ?? 0,
        },
      },
      hmDest: {
        position: {
          lat: order?.route?.destination?.lat ?? 0,
          lng: order?.route?.destination?.long ?? 0,
        },
      },
      summary: {
        duration: order?.route?.summary?.duration ?? 0,
        driveTime: order?.route?.summary?.driveTime ?? "",
        miles: order?.route?.summary?.miles ?? 0,
        practicalDeliveryTime:
          order?.route?.summary?.practicalDeliveryTime ?? "",
      },
    });
    return;
  }

  ShowGlobalSpinner("Fetching Route Data...");
  const { routes, coords1, coords2, error } = await getRoute(
    origin.value,
    dest.value,
  );

  if (error) {
    setRoute({ error: error } as any);
    console.log("error", error);
    HideGlobalSpinner();
    return;
  }

  setRoute((prev) => {
    const arrival = { ...routes?.[0]?.sections?.[0]?.arrival };
    const departure = { ...routes?.[0]?.sections?.[0]?.departure };
    const summary = { ...routes?.[0]?.sections?.[0]?.summary };

    if (arrival && departure && summary) {
      summary.driveTime = formatSecondsIntoDaysAndHours(summary.duration);
      departure.newTime = new Date(departure.time);
      departure.newTime.setHours(departure.newTime.getHours() + 24);

      arrival.newTime = calculateArrivalTime(
        departure.newTime,
        summary.duration,
      );
      summary.miles = getDrivingDistance(summary.length);
      departure.newTime = departure.newTime.toISOString();
      summary.practicalDeliveryTime = getDaysAndHoursBetweenDates(
        departure.newTime,
        arrival.newTime,
      );

      if (
        prev &&
        prev.departure &&
        prev.departure.newTime &&
        prev.arrival &&
        prev.arrival.newTime
      ) {
        const minArrivalTime = calculateArrivalTime(
          new Date(prev?.departure?.newTime ?? ""),
          summary.duration,
        );
        summary.minArrivalTime = minArrivalTime;
        if (
          new Date(prev.arrival.newTime).getTime() <
          new Date(minArrivalTime).getTime()
        ) {
          summary.conflict = true;
        }
      }
    }

    return { summary, arrival, departure, hmOrigin: coords1, hmDest: coords2 };
  });

  HideGlobalSpinner();
  return { routes, coords1, coords2 };
}

interface EditOriginDestinationCardProps {
  origin?: any;
  destination?: any;
  variant?: string;
  order?: Order | null;
}

export const EditOriginDestinationCard: React.FC<
  EditOriginDestinationCardProps
> = ({ origin, destination, variant, order }) => {
  const [_origin, setOrigin] = useState<Location | null>(null);
  const [_dest, setDest] = useState<Location | null>(null);
  const [route, setRoute] = useState<Route | null>(null);

  useEffect(() => {
    setOrigin(locations.find((loc) => loc.label === origin?.name) ?? null);
  }, [origin]);

  useEffect(() => {
    setDest(locations.find((loc) => loc.label === destination?.name) ?? null);
  }, [destination]);

  useEffect(() => {
    if (order) {
      getAndSetRoute(origin, destination, setRoute, order);
    } else {
      getAndSetRoute(_origin, _dest, setRoute, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${_origin?.value?.name}-${_dest?.value?.name}`]);

  if (route?.error) {
    alert(
      "ERROR: " + route?.error?.map((err: any) => err?.title || "").join(", "),
    );
    setOrigin(null);
    setDest(null);
    setRoute(null);
  }

  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          margin: "15px 10px",
          gap: 20,
        }}
      >
        <AddressSection>
          <AddressTitle>{MapDot} Origin</AddressTitle>

          <DockInfo css={{ gap: 20 }}>
            <DockTimeStyled css={{ fontSize: 14, flex: 4 }}>
              <DockTitle>Location</DockTitle>
              <SelectLocation
                selected={_origin}
                setSelected={setOrigin}
                css={{ fontSize: 14 }}
                required
                namePrefix="origin"
              />
            </DockTimeStyled>

            <DockInfo css={{ gap: 20, flex: 4 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Gate/Dock/Yard</DockTitle>
                <SelectDock
                  disabled={true}
                  defaultValue={origin?.location}
                  namePrefix="origin"
                />
              </DockTimeStyled>

              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Load Type</DockTitle>
                <SelectLoadType
                  defaultValue={origin?.loadType}
                  namePrefix="origin"
                  required
                />
              </DockTimeStyled>
            </DockInfo>
          </DockInfo>

          <input
            type="hidden"
            name="supplierName"
            value={_origin?.value?.name ?? ""}
          />

          <DockInfo css={{ gap: 20, marginTop: 20 }}>
            <SectionTitle css={{ flex: 1 }}>Pickup Window</SectionTitle>
            <SectionTitle css={{ flex: 1 }}>Origin Contact Info</SectionTitle>
          </DockInfo>

          <DockInfo css={{ gap: 20 }}>
            <DockInfo css={{ gap: 20, flex: 1 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Ready Time</DockTitle>
                <DateTimeInput
                  defaultValue={origin?.readyTime}
                  route={route}
                  onChange={(val) => updateDestDate(val, setRoute)}
                  required
                  name="origin.readyTime"
                />
              </DockTimeStyled>

              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Close Time</DockTitle>
                <DateTimeInput
                  defaultValue={origin?.closeTime}
                  required
                  name="origin.closeTime"
                />
              </DockTimeStyled>
            </DockInfo>

            <DockInfo css={{ gap: 20, flex: 1 }}>
              <DockTimeStyled>
                <DockTitle>Contact Name</DockTitle>
                <ValidatorInput
                  defaultValue={origin?.contactName}
                  css={{ fontSize: 14 }}
                  placeholder="Enter Name"
                  name="origin.contactName"
                  required
                />
              </DockTimeStyled>

              <DockTimeStyled>
                <DockTitle>Contact Email</DockTitle>
                <ValidatorInput
                  defaultValue={origin?.contactEmail}
                  css={{ fontSize: 14 }}
                  name="origin.contactEmail"
                  placeholder="Enter Email"
                  type="email"
                  required
                />
              </DockTimeStyled>

              <DockTimeStyled>
                <DockTitle>Contact Phone</DockTitle>
                <ValidatorInput
                  defaultValue={origin?.contactPhone}
                  css={{ fontSize: 14 }}
                  name="origin.contactPhone"
                  required
                  mask="(___) ___-____"
                  maskSlots="_"
                  dataAccept="\d"
                  showFullMaskWhileTyping={true}
                  placeholder="Enter Phone"
                  exactLength={10}
                />
              </DockTimeStyled>
            </DockInfo>
          </DockInfo>

          <DockInfo css={{ gap: 20, flex: 1, marginTop: 20 }}>
            <DockTimeStyled>
              <DockTitle>Notes</DockTitle>
              <ValidatorInput
                defaultValue={origin?.notes}
                css={{ fontSize: 14 }}
                name="origin.notes"
              />
            </DockTimeStyled>
          </DockInfo>
        </AddressSection>

        <AddressSection>
          <AddressTitle>{MapDot} Destination</AddressTitle>

          <DockInfo css={{ gap: 20 }}>
            <DockTimeStyled css={{ fontSize: 14, flex: 4 }}>
              <DockTitle>Location</DockTitle>
              <SelectLocation
                selected={_dest}
                setSelected={setDest}
                css={{ fontSize: 14 }}
                required
                namePrefix="destination"
              />
            </DockTimeStyled>

            <DockInfo css={{ gap: 20, flex: 4 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Gate/Dock/Yard</DockTitle>
                <SelectDock
                  disabled={true}
                  defaultValue={destination?.location}
                  namePrefix="destination"
                />
              </DockTimeStyled>

              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Load Type</DockTitle>
                <SelectLoadType
                  defaultValue={destination?.loadType}
                  namePrefix="destination"
                  required
                />
              </DockTimeStyled>
            </DockInfo>
          </DockInfo>

          <input
            type="hidden"
            name="companyName"
            value={_dest?.value?.name ?? ""}
          />

          <DockInfo css={{ gap: 20, marginTop: 20 }}>
            <SectionTitle css={{ flex: 1 }}>Delivery Window</SectionTitle>
            <SectionTitle css={{ flex: 1 }}>
              Destination Contact Info
            </SectionTitle>
          </DockInfo>

          <DockInfo css={{ gap: 20 }}>
            <DockInfo css={{ gap: 20, flex: 1 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Open Time</DockTitle>
                <DateTimeInput
                  defaultValue={destination?.openTime}
                  required
                  name="destination.openTime"
                />
              </DockTimeStyled>

              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Must Deliver By</DockTitle>
                <DateTimeInput
                  defaultValue={destination?.mustDeliverBy}
                  route={route}
                  onChange={(val) => updateOriginDate(val, setRoute)}
                  required
                  name="destination.mustDeliverBy"
                />
              </DockTimeStyled>
            </DockInfo>

            <DockInfo css={{ gap: 20, flex: 1 }}>
              <DockTimeStyled>
                <DockTitle>Contact Name</DockTitle>
                <ValidatorInput
                  defaultValue={destination?.contactName}
                  css={{ fontSize: 14 }}
                  placeholder="Enter Name"
                  name="destination.contactName"
                  required
                />
              </DockTimeStyled>

              <DockTimeStyled>
                <DockTitle>Contact Email</DockTitle>
                <ValidatorInput
                  defaultValue={destination?.contactEmail}
                  css={{ fontSize: 14 }}
                  name="destination.contactEmail"
                  placeholder="Enter Email"
                  type="email"
                  required
                />
              </DockTimeStyled>

              <DockTimeStyled>
                <DockTitle>Contact Phone</DockTitle>
                <ValidatorInput
                  defaultValue={destination?.contactPhone}
                  css={{ fontSize: 14 }}
                  name="destination.contactPhone"
                  required
                  mask="(___) ___-____"
                  maskSlots="_"
                  dataAccept="\d"
                  showFullMaskWhileTyping={true}
                  placeholder="Enter Phone"
                  exactLength={10}
                />
              </DockTimeStyled>
            </DockInfo>
          </DockInfo>

          <DockInfo css={{ gap: 20, flex: 1, marginTop: 20 }}>
            <DockTimeStyled>
              <DockTitle>Notes</DockTitle>
              <ValidatorInput
                defaultValue={destination?.notes}
                css={{ fontSize: 14 }}
                name="destination.notes"
              />
            </DockTimeStyled>
          </DockInfo>
        </AddressSection>
      </div>
    </>
  );
};
