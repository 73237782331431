import React from "react";
import _ from "lodash";
import { isChrome, isSafari, isEdge } from "react-device-detect";

// Authorization & Authentication modules
import { Privileges, Features } from "./modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";
import AuthenticationUtils from "./modules/auth/authentication";

// Top-level view containers
import { StatusCodes } from "./pages/error/Error.page";
import ErrorContainer from "./pages/error/Error.page.container";
import CallbackContainer from "./pages/callback/Callback.page.container.js";
import { LoginLoading } from "pages/loginloading/LoginLoading.page";
import EndToEndContainer from "./pages/locations/Locations.page.container";
import ShipmentsDashboardContainer from "./pages/shipments/dashboard/Shipments.Dashboard.page.container";
import ShipmentDetailViewContainer from "./modules/shipment-detail/ShipmentDetailViewContainer";
import PublicShipmentDetailViewContainer from "./pages/shipments/public-shipment-details/PublicShipmentDetail.page.container";
import ShipmentsSearchPageContainer from "pages/shipments/search/Shipments.Search.page.container";
import CriticalPartsSearchPageContainer from "pages/critical-parts/search/CriticalParts.Search.page.container";
import CriticalPartsDetailsContainer from "pages/critical-parts/details/CriticalParts.Details.page.container";
import LocationManagementContainer from "./pages/administration/location-management/LocationManagement.page.container";
import LocationEditViewContainer from "./pages/administration/location-management/location-neworedit/LocationManagement.LocationNewOrEdit.page.container";
import LocationMatchingViewContainer from "./pages/administration/location-management/unresolved-location-matching/LocationManager.UnresolvedLocationMatching.page.container";
import OrganizationsViewContainer from "./modules/organizations/OrganizationsViewContainer";
import UsersViewContainer from "./modules/users/UsersViewContainer";
import ProfileContainer from "pages/profile/Profile.page.container";
import CreateShipmentContainer from "pages/shipments/create-shipment/CreateShipment.page.container";
import ReportDetailContainer from "pages/reports/report-detail/ReportDetail.page.container";
import ReportsContainer from "pages/reports/bi-dashboard/Reports.page.container";
import ReportBuilderContainer from "pages/reports/report-builder/ReportBuilder.page.container";
import FinVehicleDashboardContainer from "pages/finishedvehicle/dashboard/FinishedVehicle.Dashboard.page.container";
import FinVehicleSearchViewContainer from "pages/finishedvehicle/search/FinishedVehicle.Search.page.container";
import FinVehicleDetailsViewContainer from "pages/finishedvehicle/details/FinishedVehicle.Details.page.container";
import DocumentationContainer from "./modules/documentation/DocumentationContainer";
import ApiGroupContainer from "./modules/documentation/ApiGroupContainer";
import ApiSampleContainer from "./modules/documentation/ApiSampleContainer";
import ApiLogsView from "./modules/documentation/api-logs/ApiLogsView";
import { MilestoneLogsView } from "./modules/documentation/milestone-logs/MilestoneLogsView";
import TryApiContainer from "./modules/documentation/TryApiContainer";
import MetalTrackingDashboardContainer from "./modules/mt-dashboard/MetalTrackingDashboardContainer";
import MetalTrackingSearchResultsViewContainer from "./modules/mt-search-results/MetalTrackingSearchResultsViewContainer";
import MetalTrackingLocationResultsViewContainer from "./modules/mt-location-results/MetalTrackingLocationResultsViewContainer";
import InventoryViewDashboardPageContainer from "./pages/inventoryview/dashboard/InventoryView.Dashboard.page.container";
import InventoryViewDetailsPageContainer from "pages/inventoryview/details/InventoryView.Details.page.container";
import DamageViewDashboardPageContainer from "pages/damageview/dashboard/DamageView.Dashboard.page.container";
import DamageViewDetailsPageContainer from "pages/damageview/details/DamageView.Details.page.container";
import DamageViewSearchPageContainer from "pages/damageview/search/DamageView.Search.page.container";
import MilestoneDashboard from "./pages/milestone/Milestone.Dashboard.page";
import CreateMilestoneContainer from "./pages/createmilestone/CreateMilestone.page.container";
import ConnectedCarViewContainer from "./pages/connectedcar/ConnectedCar.Dashboard.page.container";
import VinViewDashboardContainer from "./pages/vinview/dashboard/VinView.Dashboard.page.container";
import VinViewSearchContainer from "./pages/vinview/search/VinView.Search.page.container";
import VinViewDetailsContainer from "./pages/vinview/details/VinView.Details.page.container";
import DriveAwayDashboardPageContainer from "./pages/driveaway/dashboard/DriveAway.Dashboard.page.container";
import DriveAwaySearchPageContainer from "./pages/driveaway/search/DriveAway.Search.page.container";
import CarrierViewDashboardContainer from "./pages/carrierview/dashboard/CarrierView.Dashboard.page.container";
import CarrierViewSearchContainer from "./pages/carrierview/search/CarrierView.Search.page.container";
import CarrierViewDetailsContainer from "./pages/carrierview/details/CarrierView.Details.page.container";
import PartViewDashboardContainer from "pages/partview/dashboard/PartView.Dashboard.page.container";
import PartViewSearchContainer from "pages/partview/search/PartView.Search.page.container";
import PartViewDetailsContainer from "pages/partview/details/PartView.Details.page.container";
import DealerPartViewDashboardContainer from "pages/partview/dealer-partview-dashboard/DealerPartView.Dashboard.page.container";
import DealerPartViewSearchContainer from "pages/partview/dealer-search/DealerPartView.Search.page.container";
import ContainerTrackingDashboardContainer from "pages/containertracking/dashboard/ContainerTracking.Dashboard.page.container";
import ContainerTrackingDetailsContainer from "pages/containertracking/details/ContainerTracking.Details.page.container";
import ContainerTrackingSearchContainer from "pages/containertracking/search/ContainerTracking.Search.page.container";
import ContainerTrackingManagementContainer from "pages/containertracking/container-management/ContainerManagement.page.container.js";
import SurgicalToteTrackingDashboardContainer from "pages/surgicaltotetracking/dashboard/SurgicalToteTracking.Dashboard.page.container";
import SurgicalToteTrackingDetailsContainer from "pages/surgicaltotetracking/details/SurgicalToteTracking.Details.page.container";
import SurgicalToteTrackingSearchContainer from "pages/surgicaltotetracking/search/SurgicalToteTracking.Search.page.container";

import UnsubscribeContainer from "./pages/unsubscribe/Unsubscribe.page.container";
import UnsubscribeV2Container from "./pages/unsubscribe/UnsubscribeV2.page.container";
import { SessionEnded } from "pages/session-ended/SessionEnded.page";

import { LOCATION_MANAGEMENT_TAB } from "pages/administration/location-management/utils/const";

// Customer-Accessible Admin Tools
import AdministrativeToolsContainer from "pages/administration/admin-tools/AdminTools.page.container";
import ShipmentNotificationViewContainer from "pages/administration/admin-tools/shipment-dwell-notification/ShipmentDwellNotificationSearch.page.container";
import ShipmentETAOverrideContainer from "pages/administration/admin-tools/shipment-eta-override/ShipmentETAOverride.page.container";
import ShipmentReportArrivalDepartureContainer from "pages/administration/admin-tools/shipment-report-arrival-departure/ShipmentReportArrivalDeparture.page.container";

// Internal Admin Tools (FV-Admin only)
import InternalToolsContainer from "pages/administration/internal-tools/InternalTools.page.container";
import VinEtaValidatorContainer from "pages/administration/internal-tools/vin-eta-validator/VinEtaValidator.page.container";
import ShipmentEtaValidatorContainer from "pages/administration/internal-tools/shipment-eta-validator/ShipmentEtaValidator.page.container";
import FreezeETAPageContainer from "pages/administration/internal-tools/freeze-eta/FreezeETA.page.container";
import DPUAdminToolContainer from "pages/administration/internal-tools/dpu-admin-tool/DPUAdminTool.page.container";
import DebugETAToolContainer from "pages/administration/internal-tools/debug-eta-tool/DebugETATool.page.container";
import FreezePartViewETA from "pages/administration/internal-tools/freeze-partview-eta-tool/FreezePartViewETATool.page.container";
import NotificationManagementPageContainer from "pages/administration/notification-management/NotificationManagement.page.container";
import SetManualETARangeToolContainer from "pages/administration/internal-tools/set-manual-eta-range-tool/SetManualETARangeTool.page.container";
import SetCurrentPlannedTriplegToolPageContainer from "pages/administration/internal-tools/set-current-planned-trip-leg/SetCurrentPlannedTriplegTool.page.container";
import SetCurrentLocationOverridePageContainer from "pages/administration/internal-tools/set-current-location/SetCurrentLocationTool.page.container";
import PartViewDealerOnboardingToolContainer from "pages/administration/internal-tools/partview-dealer-onboarding-tool/PartViewDealerOnboardingTool.page.container";
import PartViewDeliveryStatusUpdatePageContainer from "pages/administration/internal-tools/partview-delivery-status-update/PartViewDeliveryStatusUpdate.page.container";

import ComplianceDashboardPageContainer from "pages/partnerportal/compliance/Compliance.Dashboard.page.container";

import { isShipper, isCarrier } from "shared/utils/organizations.utils";
import ApplicationConfig from "application-config";
import { DynamicNormalOrdersOemView } from "pages/dynamic-normal/OrdersOEMView";
import { DynamicNormalOrdersCarrierView } from "pages/dynamic-normal/OrdersCarrierView";
import { DynamicNormalOrdersSupplierView } from "pages/dynamic-normal/OrdersSupplierView";
import { DynamicNormalOpportunies } from "pages/dynamic-normal/Opportunities";

import { RtsCaoOemView } from "pages/rtscao/OrdersOEMView";

// Route Groups
// Each visible group will appear as a collapsible section in the primary navigation
// Hidden groups and routes will not appear in the primary navigation
// Routes are hidden in the primary navigation from users without the specified privileges/features
// Users without the specified privileges/features will be redirected if they visit a route directly
export const privateRouteGroupsMap = {
  /*
  GROUP_KEY: {
    // Optional - set to true if the group should not appear in navbar (default:
    false)
    hidden: false,
    // Optional - Array of authorization conditions to expand the section by default.
    //            If any condition passes, the section will be open.
    defaultOpen: [
      // Condition 1
      { privileges: [...], features: [...], organizationTypes: [...] },
      // Condition 2
      { features: [Features.FINISHED_VEHICLE] } // condition 2
    ],

    routes: {
      CHILD_ROUTE_KEY: {
        path: "/path-to-show-this-view/:param",

        // Optional - User must have at least 1 of the privileges specified
        privileges: [Privileges.FOO, Privileges.BAR],

        // Optional - User must have at least 1 of the features specified
        features: [Features.FOO, Features.BAR],

        // Top-level view to show when user visits the route (unauthorized users
        // will be redirected)
        view: <AnyViewContainer />,

        // Optional - set to true if the route link should not appear in navbar
        // (default: false)
        hidden: false,

        // Optional - set this if 1) your route is hidden, and 2) the route that
        // should be highlighted in the sidenav is _not_ the first route in your
        // group (which is the default)
        navProxyRoute: () => routesMap.ANY_ROUTE
      }
    }
  },
  */
  SHIPMENTS: {
    hidden: false,
    routes: {
      ROOT: {
        path: "/",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentsDashboardContainer />,
      },
      CREATE_SHIPMENT: {
        path: "/create-shipment",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <CreateShipmentContainer />,
      },
      SHIPMENT_DETAIL: {
        path: "/shipments/:shipment_id",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentDetailViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT,
      },
      SHIPMENT_CHILD_DETAIL: {
        path: "/shipments/:shipment_id/:child_shipment_id",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentDetailViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT,
      },
      SHIPMENT_DETAIL_CUSTOMER: {
        path: "/carriers/:carrier_scac/shipments/:creator_shipment_id",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentDetailViewContainer customerView={true} />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT,
      },
      SHIPMENT_DETAIL_FINISHED_VEHICLE: {
        path: "/carriers/:carrier_scac/shipments/:creator_shipment_id/vin/:vin",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentDetailViewContainer finishedVehicleView={true} />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT,
      },
      SHIPMENT_SEARCH: {
        path: "/search",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentsSearchPageContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT,
      },

      CRITICAL_PARTS_DETAILS: {
        path: "/critical-parts/details/:location_id/:part_number",
        privileges: [Privileges.ANY],
        features: [Features.CRITICAL_PARTS],
        organizationTypes: [OrganizationType.SHIPPER],
        hidden: true,
        view: <CriticalPartsDetailsContainer />,
        navProxyRoute: () => routesMap.CRITICAL_PARTS,
      },

      CRITICAL_PARTS: {
        path: "/critical-parts",
        privileges: [Privileges.ANY],
        features: [Features.CRITICAL_PARTS],
        organizationTypes: [OrganizationType.SHIPPER],
        view: <CriticalPartsSearchPageContainer />,
      },
    },
  },
  RTSCAO: {
    routes: {
      RTSCAO_DASHBOARD: {
        path: "/rts-cao",
        view: <RtsCaoOemView />,
        privileges: [Privileges.READY_2_SHIP_USER],
      },
    },
  },
  DYNAMIC_NORMAL: {
    routes: {
      // ORDERS: {
      //   path: "/dynamic-normal/orders",
      //   privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
      //   view: <DynamicNormalOrders />,
      // },
      ORDERS_OEM: {
        path: "/dynamic-normal/orders-oem",
        privileges: [Privileges.DYNAMIC_NORMAL],
        view: <DynamicNormalOrdersOemView />,
      },
      ORDERS_SUPPLIER: {
        path: "/dynamic-normal/orders-supplier",
        privileges: [Privileges.DYNAMIC_NORMAL],
        view: <DynamicNormalOrdersSupplierView />,
      },
      ORDERS_CARRIER: {
        path: "/dynamic-normal/orders-carrier",
        privileges: [Privileges.DYNAMIC_NORMAL],
        view: <DynamicNormalOrdersCarrierView />,
      },
      OPPORTUNITIES: {
        path: "/dynamic-normal/opportunities",
        privileges: [Privileges.DYNAMIC_NORMAL],
        view: <DynamicNormalOpportunies />,
        hidden: true,
      },
    },
  },
  FINISHED_VEHICLE: {
    routes: {
      FINISHED_VEHICLE: {
        path: "/finishedvehicle",
        features: [Features.FINISHED_VEHICLE],
        view: <FinVehicleDashboardContainer />,
      },
      CONNECTED_CAR: {
        path: "/connected-car",
        features: [Features.CONNECTED_CAR],
        view: <ConnectedCarViewContainer />,
      },
      INVENTORY_VIEW_DASHBOARD: {
        path: "/inventoryview",
        // Once we want to rely on the feature.
        // - Clean up the hardcoded valeus in AppConAuthorization.
        // - Uncomment the feature check.
        // - Uncomment the privilege check.
        // - Remove the globalAppConfig check.
        // privileges: [Privileges.INV_DASHBOARD],
        features: [Features.INVENTORY_VIEW],
        // globalAppConfig: { "inventoryView.visible": true },
        view: <InventoryViewDashboardPageContainer />,
      },
      INVENTORY_VIEW_DETAILS: {
        path: "/inventoryview/details/:location_id",
        // Once we want to rely on the feature.
        // - Clean up the hardcoded valeus in AppConAuthorization.
        // - Uncomment the privilege check.
        // - Uncomment the feature check.
        // - Remove the globalAppConfig check.
        // privileges: [Privileges.INV_DASHBOARD],
        features: [Features.INVENTORY_VIEW],
        // globalAppConfig: { "inventoryView.visible": true },
        view: <InventoryViewDetailsPageContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INVENTORY_VIEW_DASHBOARD,
      },
      DAMAGE_VIEW_DASHBOARD: {
        path: "/damageview",
        privileges: [Privileges.VIEW_DAMAGEVIEW_DASHBOARD],
        features: [Features.DAMAGE_VIEW],
        view: <DamageViewDashboardPageContainer />,
      },
      DAMAGE_VIEW_DETAILS: {
        path: "/damageview/details/:submission_id",
        privileges: [Privileges.VIEW_DAMAGEVIEW_DASHBOARD],
        features: [Features.DAMAGE_VIEW],
        view: <DamageViewDetailsPageContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.DAMAGE_VIEW_DASHBOARD,
      },
      DAMAGE_VIEW_SEARCH: {
        path: "/damageview/search",
        privileges: [Privileges.VIEW_DAMAGEVIEW_DASHBOARD],
        features: [Features.DAMAGE_VIEW],
        view: <DamageViewSearchPageContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.DAMAGE_VIEW_DASHBOARD,
      },
      VIN_SEARCH: {
        path: "/vinsearch",
        features: [Features.FINISHED_VEHICLE],
        view: <FinVehicleSearchViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.FINISHED_VEHICLE,
      },
      VIN_DETAILS: {
        path: "/vindetails/:entity_id",
        features: [Features.FINISHED_VEHICLE],
        view: <FinVehicleDetailsViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.FINISHED_VEHICLE,
      },
      MILESTONE_DASHBOARD: {
        path: "/milestones",
        features: [Features.MILESTONE],
        view: <MilestoneDashboard />,
      },
      MILESTONE_CREATE_MILESTONE_EVENT: {
        path: "/milestones/create-milestone-event",
        features: [Features.MILESTONE],
        view: <CreateMilestoneContainer />,
      },
      VINVIEW_DASHBOARD: {
        path: "/vinview",
        privileges: [Privileges.ANY],
        organizationTypes: [OrganizationType.DEALER],
        view: <VinViewDashboardContainer />,
      },
      VINVIEW_SHIPPER_DASHBOARD: {
        path: "/vinview",
        privileges: [Privileges.ANY],
        features: [Features.VIN_VIEW],
        organizationTypes: [OrganizationType.SHIPPER],
        view: <VinViewDashboardContainer />,
      },
      VINVIEW_SEARCH: {
        path: "/vinview/search",
        privileges: [Privileges.ANY],
        organizationTypes: [OrganizationType.DEALER],
        hidden: true,
        view: <VinViewSearchContainer />,
        navProxyRoute: () => routesMap.VINVIEW_DASHBOARD,
      },
      VINVIEW_SHIPPER_SEARCH: {
        path: "/vinview/search",
        privileges: [Privileges.ANY],
        features: [Features.VIN_VIEW],
        organizationTypes: [OrganizationType.SHIPPER],
        hidden: true,
        view: <VinViewSearchContainer />,
        navProxyRoute: () => routesMap.VINVIEW_SHIPPER_DASHBOARD,
      },
      VINVIEW_DETAILS: {
        path: "/vinview/details/:internal_id",
        privileges: [Privileges.ANY],
        organizationTypes: [OrganizationType.DEALER],
        hidden: true,
        view: <VinViewDetailsContainer />,
        navProxyRoute: () => routesMap.VINVIEW_DASHBOARD,
      },
      VINVIEW_SHIPPER_DETAILS: {
        path: "/vinview/dealer/:dealerOrgId/details/:internal_id",
        privileges: [Privileges.ANY],
        features: [Features.VIN_VIEW],
        organizationTypes: [OrganizationType.SHIPPER],
        hidden: true,
        view: <VinViewDetailsContainer />,
        navProxyRoute: () => routesMap.VINVIEW_SHIPPER_DASHBOARD,
      },
      CARRIERVIEW_DASHBOARD: {
        path: "/carrierview",
        privileges: [Privileges.ANY],
        organizationTypes: [OrganizationType.CARRIER],
        view: <CarrierViewDashboardContainer />,
      },
      CARRIERVIEW_SEARCH: {
        path: "/carrierview/search",
        privileges: [Privileges.ANY],
        organizationTypes: [OrganizationType.CARRIER],
        hidden: true,
        view: <CarrierViewSearchContainer />,
        navProxyRoute: () => routesMap.CARRIERVIEW_DASHBOARD,
      },
      CARRIERVIEW_DETAILS: {
        path: "/carrierview/details/:internal_id",
        privileges: [Privileges.ANY],
        organizationTypes: [OrganizationType.CARRIER],
        hidden: true,
        view: <CarrierViewDetailsContainer />,
        navProxyRoute: () => routesMap.CARRIERVIEW_DASHBOARD,
      },
      DRIVE_AWAY: {
        path: "/driveaway",
        privileges: [Privileges.ANY],
        features: [Features.DEALER_DRIVE_AWAY],
        organizationTypes: [
          OrganizationType.DEALER,
          OrganizationType.CARRIER,
          OrganizationType.SHIPPER,
        ],
        view: <DriveAwayDashboardPageContainer />,
      },
      DRIVE_AWAY_SEARCH: {
        path: "/driveaway/search",
        privileges: [Privileges.ANY],
        features: [Features.DEALER_DRIVE_AWAY],
        organizationTypes: [
          OrganizationType.DEALER,
          OrganizationType.CARRIER,
          OrganizationType.SHIPPER,
        ],
        hidden: true,
        view: <DriveAwaySearchPageContainer />,
        navProxyRoute: () => routesMap.DRIVE_AWAY,
      },
    },
  },
  PART_VIEW: {
    routes: {
      PARTVIEW_DASHBOARD: {
        path: "/partview",
        privileges: [Privileges.VIEW_PARTVIEW],
        features: [Features.PART_VIEW],
        organizationTypes: [OrganizationType.SHIPPER],
        view: <PartViewDashboardContainer />,
      },
      DEALER_PARTVIEW_DASHBOARD: {
        path: "/partview-seller",
        privileges: [Privileges.VIEW_PARTVIEW_SELLER],
        features: [Features.PART_VIEW],
        view: <DealerPartViewDashboardContainer />,
      },
      PARTVIEW_SEARCH: {
        path: "/partview/search",
        privileges: [Privileges.VIEW_PARTVIEW],
        features: [Features.PART_VIEW],
        hidden: true,
        view: <PartViewSearchContainer />,
        navProxyRoute: () => routesMap.PARTVIEW_DASHBOARD,
      },
      PARTVIEW_DETAILS: {
        path: "/partview/details/:trackingNumber",
        privileges: [Privileges.VIEW_PARTVIEW],
        features: [Features.PART_VIEW],
        hidden: true,
        view: <PartViewDetailsContainer />,
        navProxyRoute: () => routesMap.PARTVIEW_DASHBOARD,
      },
      DEALER_PARTVIEW_SEARCH: {
        path: "/partview-seller/search",
        privileges: [Privileges.VIEW_PARTVIEW_SELLER],
        features: [Features.PART_VIEW],
        hidden: true,
        view: <DealerPartViewSearchContainer />,
        navProxyRoute: () => routesMap.DEALER_PARTVIEW_DASHBOARD,
      },
      DEALER_PARTVIEW_DETAILS: {
        path: "/partview-seller/details/:trackingNumber",
        privileges: [Privileges.VIEW_PARTVIEW_SELLER],
        features: [Features.PART_VIEW],
        hidden: true,
        view: <PartViewDetailsContainer />,
        navProxyRoute: () => routesMap.DEALER_PARTVIEW_DASHBOARD,
      },
    },
  },

  PARTNERS: {
    routes: {
      PARTNER_DASHBOARD: {
        path: "/partners",
        privileges: [Privileges.ANY],
        // It's intended to allow this without the feature. This is the partner's landing page
        // which we can't change dynamically at this time.
        // features: [Features.MILESTONE],
        organizationTypes: [OrganizationType.PARTNER],
        view: <MilestoneDashboard isPartner={true} />,
      },
      PARTNER_CREATE_MILESTONE_EVENT: {
        path: "/partners/create-milestone-event",
        privileges: [Privileges.ANY],
        features: [Features.MILESTONE],
        organizationTypes: [OrganizationType.PARTNER],
        view: <CreateMilestoneContainer isPartner={true} />,
      },
    },
  },
  ASSET_TRACKING: {
    routes: {
      CONTAINER_TRACKING_DASHBOARD: {
        path: "/container-tracking",
        features: [Features.CONTAINER_TRACKING],
        globalAppConfig: {
          "containerTracking.productName": "Container Tracking",
        },
        view: <ContainerTrackingDashboardContainer />,
      },
      CONTAINER_TRACKING_DETAILS: {
        path: "/container-tracking/details/:id",
        features: [Features.CONTAINER_TRACKING],
        globalAppConfig: {
          "containerTracking.productName": "Container Tracking",
        },
        hidden: true,
        view: <ContainerTrackingDetailsContainer />,
        navProxyRoute: () => routesMap.CONTAINER_TRACKING_DASHBOARD,
      },
      CONTAINER_TRACKING_SEARCH: {
        path: "/container-tracking/search",
        features: [Features.CONTAINER_TRACKING],
        globalAppConfig: {
          "containerTracking.productName": "Container Tracking",
        },
        hidden: true,
        view: <ContainerTrackingSearchContainer />,
        navProxyRoute: () => routesMap.CONTAINER_TRACKING_DASHBOARD,
      },
      CONTAINER_TRACKING_MANAGEMENT: {
        path: "/container-tracking-management",
        features: [Features.CONTAINER_TRACKING],
        globalAppConfig: {
          "containerTracking.productName": "Container Tracking",
        },
        organizationTypes: [OrganizationType.SHIPPER],
        view: <ContainerTrackingManagementContainer />,
      },
      METAL_TRACKING_DASHBOARD: {
        path: "/plant-asset",
        features: [Features.PLANT_ASSET_TRACKING],
        view: <MetalTrackingDashboardContainer />,
      },
      METAL_TRACKING_SEARCH_RESULTS: {
        path: "/plant-asset/search-results",
        features: [Features.PLANT_ASSET_TRACKING],
        view: <MetalTrackingSearchResultsViewContainer />,
        hidden: true,
      },
      METAL_TRACKING_LOCATION_RESULTS: {
        path: "/plant-asset/location-results/:rackLocation",
        features: [Features.PLANT_ASSET_TRACKING],
        view: <MetalTrackingLocationResultsViewContainer />,
        hidden: true,
      },
      SURGICAL_TOTE_TRACKING_DASHBOARD: {
        path: "/healthcare-container-tracking",
        privileges: [
          Privileges.VIEW_HEALTHCARE_TRACKING,
          Privileges.MANAGE_HEALTHCARE_TRACKING,
        ],
        organizationTypes: [OrganizationType.HEALTHCAREPROVIDER],
        view: <SurgicalToteTrackingDashboardContainer />,
      },
      SURGICAL_TOTE_TRACKING_ROUTE_CONTAINER_MANAGEMENT: {
        path: "/container-tracking-management",
        privileges: [Privileges.MANAGE_HEALTHCARE_TRACKING],
        organizationTypes: [OrganizationType.HEALTHCAREPROVIDER],
        view: <ContainerTrackingManagementContainer />,
      },
      SURGICAL_TOTE_TRACKING_DETAILS: {
        path: "/healthcare-container-tracking/details/:id",
        privileges: [
          Privileges.VIEW_HEALTHCARE_TRACKING,
          Privileges.MANAGE_HEALTHCARE_TRACKING,
        ],
        organizationTypes: [OrganizationType.HEALTHCAREPROVIDER],
        hidden: true,
        view: <SurgicalToteTrackingDetailsContainer />,
        navProxyRoute: () => routesMap.SURGICAL_TOTE_TRACKING_DASHBOARD,
      },
      SURGICAL_TOTE_TRACKING_SEARCH: {
        path: "/healthcare-container-tracking/search",
        privileges: [
          Privileges.VIEW_HEALTHCARE_TRACKING,
          Privileges.MANAGE_HEALTHCARE_TRACKING,
        ],
        organizationTypes: [OrganizationType.HEALTHCAREPROVIDER],
        hidden: true,
        view: <SurgicalToteTrackingSearchContainer />,
        navProxyRoute: () => routesMap.SURGICAL_TOTE_TRACKING_DASHBOARD,
      },
    },
  },
  PARTNER_PORTAL: {
    routes: {
      COMPLIANCE: {
        path: "/compliance",
        features: [Features.PARTNER_PORTAL_COMPLIANCE],
        view: <ComplianceDashboardPageContainer />,
      },
      COMPLIANCE_REPORT_DETAILS: {
        path: "/compliance/reports/:reportId",
        features: [Features.PARTNER_PORTAL_COMPLIANCE],
        view: <ReportDetailContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.COMPLIANCE,
      },
    },
  },
  BUSINESS_INTELLIGENCE: {
    routes: {
      REPORTS: {
        path: "/reports",
        privileges: [Privileges.VIEW_REPORTS],
        view: <ReportsContainer />,
      },
      REPORT_BUILDER: {
        path: "/report_builder",
        privileges: [Privileges.BUILD_REPORT],
        view: <ReportBuilderContainer />,
      },
      REPORT_DETAIL: {
        path: "/reports/:reportId",
        privileges: [Privileges.VIEW_REPORTS],
        view: <ReportDetailContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.REPORTS,
      },
      FILTERED_REPORT_DETAIL: {
        path: "/reports/:reportId/:filterSetId",
        privileges: [Privileges.VIEW_REPORTS],
        view: <ReportDetailContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.REPORTS,
      },
      END_TO_END: {
        path: "/locations",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS,
        ],
        view: <EndToEndContainer />,
      },
    },
  },
  ADMINISTRATION: {
    routes: {
      LOCATION_MANAGEMENT: {
        path: "/manage/locations",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS,
        ],
        view: (
          <LocationManagementContainer
            selectedIndex={LOCATION_MANAGEMENT_TAB.LOCATION_MANAGEMENT}
          />
        ),
      },
      LOCATION_LADS: {
        path: "/manage/locations/lads",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS,
        ],
        hidden: true,
        view: (
          <LocationManagementContainer
            selectedIndex={LOCATION_MANAGEMENT_TAB.LOCATION_LADS}
          />
        ),
      },
      LOCATION_UNRESOLVED: {
        path: "/manage/locations/unresolved",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS,
        ],
        hidden: true,
        view: (
          <LocationManagementContainer
            selectedIndex={LOCATION_MANAGEMENT_TAB.LOCATION_UNRESOLVED}
          />
        ),
      },
      EDIT_LOCATION: {
        path: "/manage/locations/edit/:location_id",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS,
        ],
        view: <LocationEditViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.LOCATION_MANAGEMENT,
      },
      CREATE_LOCATION: {
        path: "/manage/locations/new",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS,
        ],
        view: <LocationEditViewContainer isCreateMode={true} />,
        hidden: true,
        navProxyRoute: () => routesMap.LOCATION_MANAGEMENT,
      },
      LOCATION_MATCHING: {
        path: "/locationmatching/:location_id",
        privileges: [Privileges.MANAGE_SHIPPER_LOCATIONS],
        view: <LocationMatchingViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.LOCATION_MANAGEMENT,
      },
      LOCATION_LINKING: {
        path: "/locationlinking/:location_id",
        privileges: [Privileges.MANAGE_SHIPPER_LOCATIONS],
        view: <LocationMatchingViewContainer locationLinking={true} />,
        hidden: true,
        navProxyRoute: () => routesMap.LOCATION_MANAGEMENT,
      },
      USER_MANAGEMENT: {
        path: "/manage/users",
        privileges: [Privileges.MANAGE_USERS],
        view: <UsersViewContainer />,
      },
      ORGANIZATION_MANAGEMENT: {
        path: "/manage/organizations",
        privileges: [Privileges.MANAGE_ORGANIZATIONS],
        view: <OrganizationsViewContainer />,
      },
      NOTIFICATION_MANAGEMENT: {
        path: "/manage/notification-management",
        features: [Features.NOTIFICATION_MANAGEMENT],
        organizationTypes: [OrganizationType.SHIPPER],
        view: <NotificationManagementPageContainer />,
      },
      // Customer-Accessible Admin Tools
      ADMINISTRATIVE_TOOLS: {
        path: "/manage/administrative-tools",
        features: [Features.ADMINISTRATIVE_TOOLS],
        privileges: [Privileges.VIEW_ADMINISTRATIVE_TOOLS],
        organizationTypes: [OrganizationType.SHIPPER],
        view: <AdministrativeToolsContainer />,
      },
      SHIPMENT_DWELL_NOTIFICATION: {
        hidden: true,
        path: "/manage/administrative-tools/notification-management/shipment-dwell-notification",
        features: [Features.SHIPMENT_DWELL_NOTIFICATION_MANAGEMENT],
        privileges: [Privileges.MANAGE_SHIPMENT_DWELL_NOTIFICATIONS],
        organizationTypes: [OrganizationType.SHIPPER],
        view: <ShipmentNotificationViewContainer />,
        navProxyRoute: () => routesMap.ADMINISTRATIVE_TOOLS,
      },
      SHIPMENT_ETA_OVERRIDE: {
        hidden: true,
        path: "/manage/administrative-tools/shipment-eta-override",
        features: [Features.ADMINISTRATIVE_TOOLS],
        privileges: [Privileges.VIEW_ADMINISTRATIVE_TOOLS],
        organizationTypes: [OrganizationType.SHIPPER],
        view: <ShipmentETAOverrideContainer />,
        navProxyRoute: () => routesMap.ADMINISTRATIVE_TOOLS,
      },
      SHIPMENT_REPORT_ARRIVAL_DEPARTURE: {
        hidden: true,
        path: "/manage/administrative-tools/shipment-report-arrival-departure",
        features: [Features.ADMINISTRATIVE_TOOLS],
        privileges: [Privileges.VIEW_ADMINISTRATIVE_TOOLS],
        organizationTypes: [OrganizationType.SHIPPER],
        view: <ShipmentReportArrivalDepartureContainer />,
        navProxyRoute: () => routesMap.ADMINISTRATIVE_TOOLS,
      },
      // Internal Admin Tools (FVAdmin only)
      INTERNAL_TOOLS: {
        path: "/internal-tools",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <InternalToolsContainer />,
      },
      VIN_ETA_VALIDATOR: {
        path: "/internal-tools/vin-eta-validator",
        privileges: [Privileges.FV_ADMIN_TOOL, Privileges.VIN_ETA_VALIDATOR],
        view: <VinEtaValidatorContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      SHIPMENT_ETA_VALIDATOR: {
        path: "/internal-tools/shipment-eta-validator",
        privileges: [
          Privileges.FV_ADMIN_TOOL,
          Privileges.SHIPMENT_ETA_VALIDATOR,
        ],
        view: <ShipmentEtaValidatorContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      FREEZE_ETA: {
        path: "/internal-tools/freeze-etas",
        privileges: [Privileges.FV_ADMIN_TOOL, Privileges.FREEZE_ETA],
        view: <FreezeETAPageContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      DPU_ADMIN_TOOL: {
        path: "/internal-tools/dpu-admin-tool",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <DPUAdminToolContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      DEBUG_ETA_TOOL: {
        path: "/internal-tools/debug-eta-tool",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <DebugETAToolContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      FREEZE_PARTVIEW_ETA_TOOL: {
        path: "/internal-tools/freeze-partview-eta-tool",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <FreezePartViewETA />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      SET_MANUAL_ETA_RANGE_TOOL: {
        path: "/internal-tools/set-manual-eta-range-tool",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <SetManualETARangeToolContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      PARTVIEW_DEALER_ONBOARDING_TOOL: {
        path: "/internal-tools/partview-dealer-onboarding-tool",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <PartViewDealerOnboardingToolContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      PARTVIEW_DELIVERY_STATUS_UPDATE: {
        path: "/internal-tools/partview-delivery-status-update",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <PartViewDeliveryStatusUpdatePageContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      SET_CURRENT_PLANNED_TRIP_LEG: {
        path: "/internal-tools/set-current-planned-trip-leg",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <SetCurrentPlannedTriplegToolPageContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
      CURRENT_LOCATION_OVERRIDE_TOOL: {
        path: "/internal-tools/current-location-override",
        privileges: [Privileges.FV_ADMIN_TOOL],
        view: <SetCurrentLocationOverridePageContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.INTERNAL_TOOLS,
      },
    },
  },
  DOCUMENTATION: {
    hidden: true,
    routes: {
      API_LOGS: {
        path: "/documentation/api-logs",
        privileges: [Privileges.ANY],
        view: <ApiLogsView />,
      },
      MILESTONE_LOGS: {
        path: "/documentation/milestone-logs",
        privileges: [Privileges.ANY],
        view: <MilestoneLogsView />, // TODO SH-5882: Swap with the new page.
      },
      DOCUMENTATION: {
        path: "/documentation/:filename",
        privileges: [Privileges.ANY],
        view: <DocumentationContainer />,
      },
      DOCUMENTATION_URL: {
        path: "/documentation/:baseUrl/:filename",
        privileges: [Privileges.ANY],
        view: <DocumentationContainer />,
      },
      API_GROUP: {
        path: "/documentation/api-group/:filename",
        privileges: [Privileges.ANY],
        view: <ApiGroupContainer />,
      },
      API_SAMPLE: {
        path: "/documentation/api-sample/:filename",
        privileges: [Privileges.ANY],
        view: <ApiSampleContainer />,
      },
      TRY_API: {
        path: "/documentation/utilities/try-api",
        privileges: [Privileges.ANY],
        view: <TryApiContainer />,
      },
    },
  },
  USER: {
    hidden: true,
    routes: {
      PROFILE: {
        path: "/profile",
        privileges: [Privileges.ANY],
        view: <ProfileContainer />,
      },
    },
  },
  UNAUTHENTICATED: {
    hidden: true,
    routes: {
      NOT_FOUND: {
        path: "/notfound",
        view: <ErrorContainer statusCode={StatusCodes.PAGE_NOT_FOUND} />,
      },
      UNSUBSCRIBE: {
        path: "/unsubscribe/:guid/:category",
        view: <UnsubscribeContainer />,
        isNavigationHidden: true,
        isHeaderHidden: true,
      },
      UNSUBSCRIBE_V2: {
        path: "/unsubscribe/v2/:ruleUuid/:unsubscribeUuid",
        view: <UnsubscribeV2Container />,
        isNavigationHidden: true,
        isHeaderHidden: true,
      },
      CALLBACK: {
        path: "/callback",
        view: <CallbackContainer />,
        isHeaderHidden: true,
      },
      SAML_CALLBACK: {
        path: "/saml-callback",
        view: <CallbackContainer />,
      },
      LOGIN_LOADING: {
        path: "/login-loading",
        view: <LoginLoading />,
      },
      VERIFY_USER_ERROR: {
        path: "/verifyUserError",
        view: <ErrorContainer statusCode={StatusCodes.UNAUTHORIZED} />,
      },
      ACCESS_FORBIDDEN_ERROR: {
        path: "/accessForbiddenError",
        view: <ErrorContainer statusCode={StatusCodes.ACCESS_FORBIDDEN} />,
      },
      UNSUPPORTED_BROWSER: {
        path: "/unsupportedbrowser",
        view: <ErrorContainer statusCode={StatusCodes.NOT_SUPPORTED} />,
      },
      SESSION_ENDED: {
        path: "/session-ended",
        view: <SessionEnded />,
        isNavigationHidden: true,
        isHeaderHidden: true,
      },
    },
  },
};

/*
 * Public routes
 */
const publicRouteGroupsMap = {
  SHIPMENTS: {
    hidden: true,
    routes: {
      ROOT: {
        path: "/shipment",
        view: <PublicShipmentDetailViewContainer />,
        isNavigationHidden: true,
        isHeaderHidden: false,
      },
    },
  },
  DOCUMENTATION: {
    hidden: true,
    routes: {},
  },
  UNAUTHENTICATED: {
    hidden: true,
    routes: {
      NOT_FOUND: {
        path: "/notfound",
        view: <ErrorContainer statusCode={StatusCodes.PAGE_NOT_FOUND} />,
      },
      UNSUBSCRIBE: {
        path: "/unsubscribe/:guid/:category",
        view: <UnsubscribeContainer />,
        isNavigationHidden: true,
        isHeaderHidden: true,
      },
    },
  },
};

export const routeGroupsMap = ApplicationConfig.IS_PUBLIC
  ? publicRouteGroupsMap
  : privateRouteGroupsMap;

// Build an object containing all routes within all groups
const buildRoutesMap = () => {
  let routes = {};
  for (const navGroupKey in routeGroupsMap) {
    // Set navGroup for each route to reference if needed.
    const navGroup = routeGroupsMap[navGroupKey];
    for (const routeKey in navGroup.routes) {
      navGroup.routes[routeKey].navGroup = navGroupKey;
    }

    // Create a flat map of all routes
    routes = Object.assign({}, routes, navGroup.routes);

    // H1-1882: we want to highlight something in the sidenav even when the
    // route we're showing isn't in the nav menu. Normally the hidden route
    // would be a child that you only reach through a parent, in which case we'd
    // generally want to show the parent. For most routes that's the first route
    // listed in their group. Some groups have more than one logical parent --
    // for these cases, individual routes can override the default with a custom
    // navProxyRoute in their route definition.
    const groupRoot = _.values(navGroup.routes)[0];
    _.forEach(navGroup.routes, (r) => {
      if (!r.navProxyRoute) {
        r.navProxyRoute = () => groupRoot;
      }
    });
  }
  return routes;
};
export const routesMap = buildRoutesMap();

export function routeForLocation(location, defaultRoute = routesMap.NOT_FOUND) {
  if (
    location.pathname !== "/callback" &&
    location.pathname !== "/saml_callback"
  ) {
    AuthenticationUtils.setLastRequestedUrl(location.pathname);
  }
  return _.get(routesMap, location.type, defaultRoute);
}

export function navigationForLocation(location) {
  return location.pathname !== "/registration";
}

export function verifyRouteForShipper(location, organization) {
  const forbiddenPaths = ["/locations"];

  if (
    !_.isEmpty(organization) &&
    !isShipper(organization) &&
    forbiddenPaths.some((p) => location.pathname === p)
  ) {
    location.pathname = "/accessForbiddenError";
    location.type = "ACCESS_FORBIDDEN_ERROR";
  }

  return location;
}

export function verifyRouteForCarrier(location, organization) {
  const forbiddenPaths = ["/create-shipment"];

  if (
    !_.isEmpty(organization) &&
    !isCarrier(organization) &&
    forbiddenPaths.some((p) => location.pathname === p)
  ) {
    location.pathname = "/accessForbiddenError";
    location.type = "ACCESS_FORBIDDEN_ERROR";
  }

  return location;
}

// DEV-127 Check browser for Chrome or Safari
//  if unsupported browser, redirect to unsupported view
export function verifyBrowser(location) {
  if (!(isChrome || isSafari || isEdge)) {
    location.pathname = "/unsupportedbrowser";
    location.type = "UNSUPPORTED_BROWSER";
  }

  return location;
}

export function getLocation(state) {
  return state.location;
}
