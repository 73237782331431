/* eslint-disable curly */
"use client";
import { useEffect, useRef } from "react";
import { getFormData, setFormData } from "./formHelper";

interface FormProps {
  onSubmit?: any;
  className?: string;
  style?: React.CSSProperties;
  children?: any;
  method?: string;
  action?: string;
  fRef?: any;
}

export function Form(props: FormProps) {
  const submitFunc = (ev: any) => {
    // debugger;
    if (props.onSubmit === undefined) return;
    ev.preventDefault();
    const frm = ev.target as any;
    if (!frm) return;
    if (frm.checkValidity() === false) return;
    const data = getFormData(ev.target);
    const results = props.onSubmit(data);
    // if results is a promise, wait for it to resolve before resetting the form
    if (results instanceof Promise) {
      results.then((r) => {
        console.log("r", r);
        if (r === false) return;
        frm.reset();
      });
    } else {
      console.log("results", results);
      if (results === false) return;
    }

    // frm.reset();
  };
  return (
    <form
      onSubmit={submitFunc}
      className={props.className}
      style={props.style}
      method={props.method}
      action={props.action}
      ref={props.fRef}
    >
      {props.children}
    </form>
  );
}

export function useForm({ onSubmit, initialData }: any = {}) {
  const formRef = useRef(null);
  const failedCount = useRef(0);
  useEffect(() => {
    if (!formRef.current) return;
    setFormData(formRef.current, initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const internalSetFormData = (data: any) => {
    if (!formRef.current) {
      if (failedCount.current > 10) throw new Error("Form not found");
      failedCount.current++;

      setTimeout(() => internalSetFormData(data), 0);
    } else {
      setFormData(formRef.current, data);
    }
  };
  const internalGetFormData = () => getFormData(formRef.current);

  const WrappedForm = (props: FormProps) => (
    <Form fRef={formRef} onSubmit={onSubmit} {...props}>
      {props.children}
    </Form>
  );

  return [WrappedForm, internalSetFormData, internalGetFormData] as const;
}
