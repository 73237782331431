/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable curly */
/* eslint-disable no-labels */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { useQueryClient } from "@tanstack/react-query";
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { Modal } from "components/molecules/Modal.molecule";
import { Form } from "pages/rtscao/formLibrary/useForm";
import { orderService } from "pages/rtscao/service/orderServices";
import { EditOriginDestinationCard } from "../OriginDestinationCard/EditOriginDestinationCard";
import {
  Button,
  Card,
  formatSecondsIntoDaysAndHours,
  getDaysAndHoursBetweenDates,
  GreenButton,
} from "../Shared";
import EditShipmentDetails from "../ShipmentDetailsCard/EditShipmentDetails";
import { StepIndicator } from "./StepIndicator"; // Add this import
import { BasicInfo } from "./BasicInfo";
import { R2SProvider } from "pages/rtscao/service/R2SContext";
import { useState } from "react";
import { setSearchParams } from "pages/rtscao/hooks/useSearchParams";
import { Icon } from "components/atoms/Icon.atom";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import OpportunityDetails from "../ShipmentDetailsCard/OpportunityDetails";
import { findOpportunitiesV2 } from "pages/rtscao/service/opportunityServiceV2";
import {
  calculateArrivalTime,
  getDrivingDistance,
  getRoute,
} from "pages/rtscao/service/heremapService";
import { calculateCost } from "pages/rtscao/service/costService";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import { useSelector } from "react-redux";

interface OrderTypeOption {
  value: string;
  label: string;
  icon: string;
}

const orderTypeOptions: OrderTypeOption[] = [
  { value: "supplier", label: "Supplier Part Order", icon: "📦" },
  { value: "shipping", label: "Shipping Capacity", icon: "🚚" },
];

interface CreateOrderModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  plant: string | null;
  setPlant:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<null>>;
}

export function CreateOrderModal(props: CreateOrderModalProps): JSX.Element {
  const queryClient = useQueryClient();
  const activeOrg = useSelector(getActiveOrganization);

  // const [orderType] = useState<string>(orderTypeOptions[1].value);
  const orderType = orderTypeOptions[1].value;
  const modalWidth = "1200px";
  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState<any>(null);

  const createNewOrder = async (data: any) => {
    if (step === 1) {
      ShowGlobalSpinner("Getting Quotes...");
      data.orgId = activeOrg.organization_id;
      const newOrder = orderService.createOrder(data);
      const { routes, coords1, coords2 } = await getRoute(
        newOrder?.origin,
        newOrder?.destination,
      );

      const arrival = routes?.[0]?.sections?.[0]?.arrival;
      const departure = routes?.[0]?.sections?.[0]?.departure;
      const summary = routes?.[0]?.sections?.[0]?.summary;

      if (arrival && departure && summary) {
        summary.driveTime = formatSecondsIntoDaysAndHours(summary.duration);
        departure.newTime = new Date(departure.time);
        departure.newTime.setHours(departure.newTime.getHours() + 24);

        arrival.newTime = calculateArrivalTime(
          departure.newTime,
          summary.duration,
        );
        summary.miles = getDrivingDistance(summary.length);
        summary.estimatedCost = calculateCost(summary.miles);

        departure.newTime = departure.newTime.toISOString();
        summary.practicalDeliveryTime = getDaysAndHoursBetweenDates(
          departure.newTime,
          arrival.newTime,
        );
      }

      newOrder.route = {
        origin: {
          lat: coords1.position.lat,
          long: coords1.position.lng,
        },
        destination: {
          lat: coords2.position.lat,
          long: coords2.position.lng,
        },
        summary,
        arrival,
        departure,
      };

      newOrder.orderType = orderType;
      newOrder.offers = [
        {
          originalMiles: data?.route?.summary?.miles,
          newCost: data?.route?.summary?.estimatedCost,
        },
      ];

      newOrder.statusItems[0].status = "active";
      const resp: any = await findOpportunitiesV2({
        og_lat: newOrder?.route?.origin?.lat,
        og_long: newOrder?.route?.origin?.long,
        dest_lat: newOrder?.route?.destination?.lat,
        dest_long: newOrder?.route?.destination?.long,
        order: newOrder,
      });

      if (
        resp.uberQuote &&
        (resp.uberQuote.code || resp.uberQuote.message || resp.uberQuote.error)
      ) {
        alert(
          `Uber Quote Error:${resp.uberQuote.code}: ${resp.uberQuote.message} ${resp.uberQuote.error}`,
        );
        HideGlobalSpinner();
        return false;
      }

      newOrder.uberQuote = resp.uberQuote;

      setFormData(newOrder);
      setStep(2);
      HideGlobalSpinner();
      return false;
    }

    if (step === 2) {
      ShowGlobalSpinner("Creating Order...");
      await orderService.addOrder({ ...formData, ...data }); // persist to db
      setSearchParams({ [`${formData.id}-expanded`]: true });
      queryClient.invalidateQueries({ queryKey: ["orders"] });

      HideGlobalSpinner();
      props.setShow(false);
      setStep(1);
      setFormData(null);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {}}
      css={{
        "--bs-modal-width": modalWidth,
        "&>div>div": { width: modalWidth },
      }}
    >
      <Modal.Header
        title="Create Order"
        customCss={{
          backgroundColor: "#282828",
          color: "white",
          borderTopRightRadius: "4px",
          borderTopLeftRadius: "4px",
        }}
        closeButton={false}
      />
      <R2SProvider value={{ editMode: true }}>
        <Form onSubmit={createNewOrder}>
          <Modal.Body>
            <StepIndicator
              currentStep={step}
              totalSteps={2}
              title={
                step === 1
                  ? "Certified OEM Supplier Shipment Data: Ready 2 Ship Profile (R2S) TM"
                  : "OEM Decision Maker Profile"
              }
            />

            {step === 1 ? (
              <ShippingForm defaultValues={formData} />
            ) : (
              <DecisionMakerForm defaultValues={formData} />
            )}
          </Modal.Body>
          <Modal.Footer
            style={{
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          >
            <Button
              type="button"
              onClick={() => (step === 1 ? props.setShow(false) : setStep(1))}
            >
              {step === 1 ? "Close" : "Go Back to Shipment Details"}
            </Button>
            <GreenButton type="submit">
              {step === 1 ? "Continue" : "Create Order"}
            </GreenButton>
          </Modal.Footer>
        </Form>
      </R2SProvider>
    </Modal>
  );
}

const DecisionMakerForm: React.FC<{ defaultValues: any }> = ({
  defaultValues = {},
}) => {
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div
        css={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          padding: "0 100px",
          alignItems: "center",
        }}
      >
        <div
          css={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div css={{ fontWeight: "bold" }}>{defaultValues?.origin?.name}</div>
          <div>{defaultValues?.origin?.address}</div>
        </div>
        <div css={{ flex: 1, maxWidth: "20px" }}>
          <Icon src={faArrowRight} />
        </div>
        <div
          css={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div css={{ fontWeight: "bold" }}>
            {defaultValues?.destination?.name}
          </div>
          <div>{defaultValues?.destination?.address}</div>
        </div>
      </div>
      <div>
        <OpportunityDetails order={defaultValues} />
      </div>
    </div>
  );
};

const ShippingForm: React.FC<{ defaultValues: any }> = ({
  defaultValues = {},
}) => {
  return (
    <>
      <BasicInfo defaultValue={defaultValues} />
      <div css={{ display: "flex", flexDirection: "column" }}>
        <EditOriginDestinationCard
          origin={defaultValues?.origin}
          destination={defaultValues?.destination}
        />
      </div>
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            margin: "15px 10px",
            gap: 20,
          }}
        >
          <Card
            css={{
              display: "flex",
              flexDirection: "column",
              padding: 10,
            }}
          >
            <EditShipmentDetails
              shipmentDetails={defaultValues?.shipmentDetails}
            />
          </Card>
        </div>
      </div>
    </>
  );
};
